import './style/style.scss'
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { ShareButtonsGrid } from './components/shareButtonsGrid/shareButtonsGrid'
import { Spinner } from './components/spinner/spinner'
import { getUrl } from './services/loadSlomoSpinData'
import { NoMedia } from './components/noMedia/noMedia'
import { ModalEmail } from './components/modalEmail/modalEmail'


function App() {
    const [media, setMedia] = useState<string>()
    const [spinner, setSpinner] = useState<boolean>(true)
    const [noFile, setNoFile] = useState(false);
    const [errorMedias, setErrorMedias] = useState<boolean>(false)
    const [modalIsOpenEmail, setIsOpenEmail] = useState<boolean>(false)

    useEffect(() => {
        const params = new URL(window.location.href).searchParams
        const file = params.get('file')

        if (file === null) {
            setMedia(undefined)
            setSpinner(false)
        } else {
            setMedia(getUrl(file))
            setSpinner(false)
        }
    }, [])

    const openModalEmail = () => {
        setIsOpenEmail(true)
    }

    const closeModalEmail = () => {
        setIsOpenEmail(false)
    }

    const errorDownload = () => {
        const popover = document.getElementById('popover-error')
        if (popover) {
            popover.style.left = '0'
            setTimeout(() => {
                popover.style.left = '-320px'
            }, 2500)
        }
    }

    const sentEmailSuccessful = () => {
        const popover = document.getElementById('popover-email')
        if (popover) {
            popover.style.left = '0'
            setTimeout(() => {
                popover.style.left = '-300px'
            }, 2000)
        }
    }

    return (
        <div className="App">
            <div className="container">
                {
                    errorMedias &&
                    <h1 className="text-wrong-gallery">
                        Something went wrong while getting the gallery, please
                        try again.
                    </h1>
                }
                {
                    media !== undefined ?
                        <div className="container-video">
                            <video
                                id="video"
                                key={media}
                                className="media-content"
                                controls >
                                <source src={media} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <ShareButtonsGrid 
                                sharing={[
                                    'email',
                                    'download',
                                    'facebook',
                                    'whatsapp',
                                    'twitter',
                                    'copy'
                                    ]} 
                                errorDownload={() => errorDownload()}
                                openModalEmail={openModalEmail} />
                        </div>
                        :
                        <NoMedia />
                }
            </div>
            <Modal
                isOpen={modalIsOpenEmail}
                onRequestClose={closeModalEmail}
                className="modal_email"
                ariaHideApp={false}
            >
                <ModalEmail
                    sentEmailSuccessful={sentEmailSuccessful}
                    errorSendEmail={errorDownload}
                    closeModalEmail={closeModalEmail}
                />
            </Modal>
            <div id="popover-error" className="popover-content">
                <p className="popover-message">An error has occurred, please try again.</p>
            </div>
            <div id="popover-email" className="popover-content">
                <p className="popover-message">Email has been sent!</p>
            </div>
            {spinner ? <Spinner /> : ''}
        </div>
    )
}

export default App
