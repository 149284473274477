import { FC, useEffect, useState } from 'react';
import { Spinner } from '../spinner/spinner';
import AWS from 'aws-sdk';

interface Props {
    alt: string;
    image: string;
    link: string;
    target: string;
    download?: boolean;
    downloadAllGallery?: boolean;
    openModalDownload?: any;
    email?: boolean;
    openModalEmail?: any;
    copy?: boolean;
    errorDownload?: any;
}

// Return the button according if the button is to download, copy, etc.
// Each button have different functions
export const ButtonShare: FC<Props> = (props) => {
    const [downloadUrl, setDownloadUrl] = useState<string>(props.link);
    const [loaderDownload, setLoaderDownload] = useState(false);

    useEffect(() => {
        if (props.download) {
            const params = new URL(window.location.href).searchParams;
            const file = params.get('file');
            file && setDownloadUrl(file);
        }
    }, [props.link]);

    const handleClickCopy = (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(props.link);
        const popover = document.getElementById('popover');
        if (popover) {
            popover.style.left = '0';
            setTimeout(() => {
                popover.style.left = '-300px';
            }, 2000);
        }
    };

    const download = () => {
        setLoaderDownload(true);
        AWS.config.update({
            accessKeyId: 'AKIA5SJOK6NPSHJVFSVP',
            secretAccessKey: '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',
        });

        const s3 = new AWS.S3();

        const params = {
            Bucket: 'gruvy-uploads',
            Key: downloadUrl,
        };

        s3.getObject(params, (err, data) => {
            if (err) {
                props.errorDownload();
                setLoaderDownload(false);
            } else {
                if (data === undefined || data.Body === undefined) {
                    props.errorDownload();
                    return;
                }

                let blob = new Blob([data.Body as BlobPart], { type: data.ContentType });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = downloadUrl;
                link.click();
                window.URL.revokeObjectURL(downloadUrl);
                setLoaderDownload(false);
            }
        });
    };

    if (props.download) {
        return (
            <a onClick={() => download()} className='button-share' rel='nofollow noopener' target={props.target}>
                {loaderDownload ? <Spinner /> : <img src={props.image} alt={props.alt} />}
            </a>
        );
    } else if (props.downloadAllGallery) {
        return (
            <a className='button-share' rel='nofollow noopener' target={props.target} onClick={props.openModalDownload}>
                <img src={props.image} alt={props.alt} />
            </a>
        );
    } else if (props.email) {
        return (
            <a
                className='button-share'
                data-link={props.link}
                rel='nofollow noopener'
                target={props.target}
                onClick={props.openModalEmail}
            >
                <img src={props.image} alt={props.alt} />
            </a>
        );
    } else if (props.copy) {
        return (
            <div>
                <a
                    className='button-share'
                    data-link={props.link}
                    rel='nofollow noopener'
                    target={props.target}
                    onClick={handleClickCopy}
                >
                    <img src={props.image} alt={props.alt} />
                </a>
                <div id='popover' className='popover-content'>
                    <p className='popover-message'>Link has been copied!</p>
                </div>
            </div>
        );
    } else {
        return (
            <a className='button-share' href={props.link} rel='nofollow noopener' target={props.target}>
                <img src={props.image} alt={props.alt} />
            </a>
        );
    }
};
