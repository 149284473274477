import { FC } from 'react'
import {
    copyUrl,
    downloadUrl,
    facebookUrl,
    urlTwitter,
    whatsappUrl,
} from '../../services/sharing'
import { ButtonShare } from '../buttonShare/buttonShare'

interface Props {
    sharing: Array<string>
    errorDownload?: any
    openModalEmail?: any
}

// For every element in the array of sharing, return the component button
// for this shared method.
export const ShareButtonsGrid: FC<Props> = (props) => {
    const displayButton = (type: string) => {
        switch (type) {
            case 'email':
                return (
                    <ButtonShare
                        alt="Send gallery by email"
                        image={`${process.env.PUBLIC_URL}/images/envelope.svg`}
                        link=""
                        target="_self"
                        key="send-email"
                        email
                        openModalEmail={props.openModalEmail}
                    />
                )
                break
            case 'download':
                return (
                    <ButtonShare
                        alt="Download"
                        image={`${process.env.PUBLIC_URL}/images/download.svg`}
                        link={downloadUrl()}
                        key="download"
                        target="_blank"
                        download
                        errorDownload={() => props.errorDownload()}
                    />
                )
                break
            case 'facebook':
                return (
                    <ButtonShare
                        alt="ddd"
                        image={`${process.env.PUBLIC_URL}/images/facebook.svg`}
                        link={facebookUrl()}
                        target="_blank"
                        key="Facebook"
                    />
                )
                break
            case 'whatsapp':
                return (
                    <ButtonShare
                        alt="Whatsapp"
                        image={`${process.env.PUBLIC_URL}/images/whatsapp.svg`}
                        link={whatsappUrl()}
                        target="_blank"
                        key="Whatsapp"
                    />
                )
                break
            case 'twitter':
                return (
                    <ButtonShare
                        alt="Twitter"
                        image={`${process.env.PUBLIC_URL}/images/twitter.svg`}
                        link={urlTwitter()}
                        target="_blank"
                        key="Twitter"
                    />
                )
                break
            case 'copy':
                return (
                    <ButtonShare
                        alt="ddd"
                        image={`${process.env.PUBLIC_URL}/images/copy.svg`}
                        link={copyUrl()}
                        target="_self"
                        key="Copy"
                        copy
                    />
                )
                break

            default:
                break
        }
    }
    return (
        <div className={'grid-buttons' }>
            {props.sharing.map((e) => displayButton(e))}
        </div>
    )
}
