import { FC, useState } from 'react'
import { sendEmail } from '../../services/sendEmail'

interface Props {
    closeModalEmail: any
    sentEmailSuccessful: any
    errorSendEmail: any
}

// Modal to send by email, do the form to set the email
export const ModalEmail: FC<Props> = (props) => {
    const [email, setEmail] = useState<string>('')

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const url = new URL(window.location.href)
        const href = url.href
        sendEmail(email, href)
            .then((res) => {
                if (res.status === 200) {
                    props.closeModalEmail()
                    props.sentEmailSuccessful()
                } else {
                    props.closeModalEmail()
                    props.errorSendEmail()
                }
            }).catch(() => {
                props.closeModalEmail()
                props.errorSendEmail()
            })
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value)
    }

    return (
        <div className="modal-email">
            <button
                className="button-modal close"
                onClick={props.closeModalEmail}
            >
                <img
                    src={process.env.PUBLIC_URL + '/images/close-button.svg'}
                    alt="close-modal"
                />
                <span className="close-sm">CLOSE</span>
            </button>
            <div className="container-modal-email">
                <h1>Share by email</h1>
                <p>Enter your email to receive the link to the gallery</p>
                <form onSubmit={handleSubmit}>
                    <input onChange={handleChange} type="email" required/>
                    <button>SEND</button>
                </form>
            </div>
        </div>
    )
}
