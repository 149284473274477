// Request to send email of the media
export const sendEmail = async (
    email: string,
    url: string
): Promise<any> => {
    const apiUrl = `https://api.touchpix.com/wp-json/tpxac/v1/share/email`
    const formData = new FormData()
    formData.append('email', email)
    formData.append('url', url)

    return new Promise(function (resolve, reject) {
        fetch(apiUrl, {
            method: 'POST',
            body: formData,
        })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                console.log(error)
                reject(error)
            })
    })
}
