// Request for load all the gallery acoording a key of gallery
// Return a object SlomoSpin with all elements
const url: string = 'https://gruvy-uploads.s3.amazonaws.com/'

export const getUrl = (key: string): string => {
    let folder = key.split('_')[0];
    let filename = key.split('_')[1];

    return url + key
}
