// Functions to retur the string of the url according the service
export function urlTwitter() {
    const twitterUrl = new URL('https://twitter.com/share')
    twitterUrl.searchParams.set(
        'url',
        `http://gruvy-uploads.com${window.location.search}`
    )
    return twitterUrl.toString()
}

export function whatsappUrl() {
    const url = new URL(window.location.href)
    const whatsappUrl = new URL('https://api.whatsapp.com/send')
    whatsappUrl.searchParams.set('text', url.toString())
    return whatsappUrl.toString()
}

export function downloadUrl() {
    const url = new URL(window.location.href)
    return url.toString()
}

export function copyUrl() {
    const url = new URL(window.location.href)
    return url.toString()
}

export function facebookUrl() {
    const fbUrl = new URL('https://www.facebook.com/sharer.php')
    fbUrl.searchParams.set(
        'u',
        `http://gruvy-uploads.com${window.location.search}`
    )
    return fbUrl.toString()
}
