import { FC } from 'react'
interface Props {}

export const NoMedia: FC<Props> = (props) => {
    return (
        <div className="no-media">
            <img src={`${process.env.PUBLIC_URL}/images/gruvy-logo.png`} alt="Gruvy - powered by OrcaVue" />
            <h2>Gruvy - All-in-one 360 spinner app</h2>
        </div>
    )
}
